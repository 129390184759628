<template>
  <main class="myreefer-wizard relative">
    <div v-if="isDevSystem == 'true'" class="rsf-dev-system-flag py2 bg-color-6 center color-white">{{ devWarnMsg }}
    </div>
    <div v-if="showProp == 'true'" class="rsf-private-bundles-flag py2 bg-color-1 center color-white">{{
      privateBundlesWarnMsg }}</div>
    <DealerHeader v-if="singleDealer.dealerLogo" :singleDealer="singleDealer" />
    <router-view :selectedProducts="selectedProducts" :updateSelectedProducts="updateSelectedProducts"
      :selectedTank="selectedTank" :updateSelectedTank="updateSelectedTank" :selectTank="selectTank"
      :measurement="measurement" :updateMeasurement="updateMeasurement" :computedHiCap="computedHiCap"
      :computedLowCap="computedLowCap" :updateLowerCap="updateLowerCap" :updateUpperCap="updateUpperCap"
      :toggleSendCopyForm="toggleSendCopyForm" :toggleSendToDealerForm="toggleSendToDealerForm"
      :updateCabinetColor="updateCabinetColor" :cabinetColorCategory="cabinetColorCategory"
      :dataNormalBundles="dataNormalBundles" :dataDiscountBundles="dataDiscountBundles" :formatCurrency="formatCurrency"
      :fetchedDetailedData="fetchedDetailedData" :updateFetchedDetailedData="updateFetchedDetailedData"
      :productCategories="productCategories" :updateProductsInCategories="updateProductsInCategories"
      :fetchProductCategories="fetchProductCategories" :selectedColor="selectedColor" :selectedCountry="selectedCountry"
      :updateCountry="updateCountry" :fetchData="fetchData" :dataFetched="dataFetched" :imageOverlays="imageOverlays"
      :loadingImages="loadingImages" :loadingProducts="loadingProducts" :loadingBundles="loadingBundles"
      :openModal="openModal" :resetTank="resetTank" :resetSelectedProducts="resetSelectedProducts"
      :resetImgOverlays="resetImgOverlays" :computeImageOverlays="computeImageOverlays" :siteLanguage="siteLanguage"
      :bundleHead="bundleHead" :discountEventActive="discountEventActive" :discountEvent="discountEvent"
      :isDealerVersion="isDealerVersion" :showBundles="showBundles" :total="total" :subTotal="subTotal"
      :myReeferDiscountAmount="myReeferDiscountAmount"
      :myReeferDiscountAmountUnformatted="myReeferDiscountAmountUnformatted" :eventDiscountAmount="eventDiscountAmount"
      :totalSavings="totalSavings" :setShowBundles="setShowBundles" :subTotalPreselected="subTotalPreselected"
      :totalPreselected="totalPreselected" :singleDealer="singleDealer">
    </router-view>
    <SendCopyForm v-if="dataFetched" :showSendCopyForm="showSendCopyForm" :toggleSendCopyForm="toggleSendCopyForm"
      :selectedProducts="selectedProducts" :selectedTank="selectedTank" :selectedColor="selectedColor"
      :siteLanguage="siteLanguage" :selectedCountry="selectedCountry" :autoOpenDealerForm="autoOpenDealerForm"
      :updateCountry="updateCountry" :discountEvent="discountEvent" :isDealerVersion="isDealerVersion" />
    <SendToDealerForm v-if="dataFetched && selectedCountry.isocode2 != 'XX'"
      :showSendToDealerForm="showSendToDealerForm" :toggleSendToDealerForm="toggleSendToDealerForm"
      :selectedProducts="selectedProducts" :selectedTank="selectedTank" :selectedColor="selectedColor"
      :updateCountry="updateCountry" :selectedCountry="selectedCountry" :updateDealer="updateDealer"
      :selectedDealer="selectedDealer" :autoOpened="autoOpened" :siteLanguage="siteLanguage"
      :discountEvent="discountEvent" :isDealerVersion="isDealerVersion" :singleDealer="singleDealer" />
    <LightBox v-if="dataFetched" :showModal="showModal" :toggleModal="toggleModal" :modalMessage="modalMessage"
      :siteLanguage="siteLanguage" :modalHeading="modalHeading" />
    <AskForMountsLightBox v-if="dataFetched" :showModal="showAskForMountsLightBox" :toggleModal="toggleShowAskForMountsLightBox"
      :modalMessageMounts="$t('rsf_no_mounts_description')" :modalHeadingMounts="$t('rsf_no_mounts_headline')"
      :modalHeadingTrays="$t('rsf_no_trays_headline')" :modalMessageTrays="$t('rsf_no_trays_description')"
      :proceedFunction="openSendCopyForm" :proceedFunction2="openSendToDealerForm" :useFunction="useFunction"
      :ledNeedsMounts="ledNeedsMounts" :ledsNeedTrays="ledsNeedTrays" />
    <BundleRulesLightbox v-if="dataFetched" :showModal="showBundlesRulesLightbox" :toggleModal="toggleBundleRulesLightbox"
      :modalHeading="bundleRulesLightboxHeading" :modalMessage="bundleRulesLightboxMessage"
      :buttonMsgCancel="bundleRulesLightboxBtnMsgCancel" :buttonMsgProceed="bundleRulesLightboxBtnMsgProceed"
      :proceedFunction="interMediateCheckForMountsAndTrays" :useFunction="proceedBundleRulesLightboxFunction" />
  </main>
</template>

<script>

import LightBox from './components/LightBox.vue'
import SendCopyForm from './components/SendCopyForm.vue'
import SendToDealerForm from './components/SendToDealerForm.vue'
import AskForMountsLightBox from './components/AskForMountsLightBox.vue'
import BundleRulesLightbox from './components/BundleRulesLightbox.vue'
import DealerHeader from './components/DealerHeader.vue'
import axios from 'axios'
import { EventBus } from './event-bus'

export default {
  name: 'App',
  components: {
    LightBox,
    SendCopyForm,
    SendToDealerForm,
    AskForMountsLightBox,
    BundleRulesLightbox,
    DealerHeader
  },
  props: ["siteLanguage", "showProp", "dealerSlug", "countryIsoCode", "dealerIsoCode"],
  data() {
    return {
      showBundles: false,

      isDevSystem: process.env.VUE_APP_RSF_DEV_SYSTEM_WARNING,
      devWarnMsg: process.env.VUE_APP_RSF_DEV_SYSTEM_MESSAGE,
      privateBundlesWarnMsg: process.env.VUE_APP_RSF_PRIVATE_BUNDLES_WARNING,
      isDealerVersion: process.env.VUE_APP_RSF_IS_DEALER_VERSION,

      autoOpened: false,

      showModal: false,
      modalMessage: '',
      modalHeading: '',

      measurement: 'metric',
      upperCap: 10,
      lowerCap: 1,

      total: '',
      totalUnformatted: 0,
      subTotal: '',
      subTotalUnformatted: 0,
      myReeferDiscountAmount: '',
      myReeferDiscountAmountUnformatted: 0,
      eventDiscountAmount: '',
      eventDiscountAmountUnformatted: 0,
      totalSavings: '',
      totalSavingsUnformatted: 0,
      subTotalPreselectedUnformatted: 0,
      subTotalPreselected: '',
      totalPreselectedUnformatted: 0,
      totalPreselected: '',


      showSendCopyForm: false,
      showSendToDealerForm: false,
      showAskForMountsLightBox: false,
      useFunction: 1,
      ledNeedsMounts: true,
      ledsNeedTrays: false,
      showBundlesRulesLightbox: false,

      fetchedDetailedData: false,
      dataFetched: false,

      loadingImages: false,
      loadingProducts: false,
      loadingBundles: false,

      selectedDealer: {},
      singleDealer: {},


      selectedCountry: {},
      selectedTank: {},
      selectedProducts: [],
      imageOverlays: [],
      selectedColor: 'Black',

      bundleHead: {
        has_discount: ''
      },
      bundleRules: [],
      bundleRulesLightboxBtnMsgCancel: '',
      bundleRulesLightboxBtnMsgProceed: '',
      bundleRulesLightboxHeading: '',
      bundleRulesLightboxMessage: '',
      proceedBundleRulesLightboxFunction: 1,
      dataNormalBundles: [],
      dataDiscountBundles: [],
      productCategories: [],

      discountEventActive: false,
      discountEvent: {
        active: false,
        discountPercent: '0',
        startingDatetime: "01-01-01 00:00:00",
        endingDatetime: '01-01-01 23:59:59',
        imagesBackgroundImageS3Path: '',
        imagesPromoImageS3Path: '',
        labelBanner1: "",
        labelHeadline: "",
        labelHeadline2: "",
        labelHeadline3: "",
        labelPDFSavingsLine: "",
        labelSubtotal: ""
      },

      cabinetColorCategory: {
        id: 123456789,
        categoryname: '',
        categoryimage_s3path: 'https://static.redseafish.com/wp-content/uploads/2022/12/02105359/cabinet_color_thumbnail_square.png',
        products: [
          {
            id: 12345,
            title: '',
            color: 'Black',
            thumbnail: 'https://static.redseafish.com/wp-content/uploads/2023/02/20090929/mr_color_black.jpeg'
          },
          {
            id: 123456,
            title: '',
            color: 'White',
            thumbnail: 'https://static.redseafish.com/wp-content/uploads/2023/02/20090932/mr_color_white.jpeg'
          },

        ]

      },
    }
  },
  created() {

    if (this.siteLanguage == '') {
      this.$i18n.locale = 'en';
      this.switchLocale();
    } else {
      this.$i18n.locale = this.siteLanguage;
      this.switchLocale();
    }

    this.cabinetColorCategory.categoryname = this.$t('rsf_myReeferCore_cabinetColor');
    this.cabinetColorCategory.products[0].title = this.$t('rsf_myReeferCore_colorBlack');
    this.cabinetColorCategory.products[1].title = this.$t('rsf_myReeferCore_colorWhite');
  },
  mounted() {
    this.loadingProducts = false;
    document.body.style.maxHeight = '100vh';
    document.body.style.overflow = 'hidden';

    setTimeout(() => {
      let topbar = document.getElementsByClassName('rsf-navbar-topline');
      let navbar = document.getElementsByClassName('rsf-nav');
      let topbarHeight = 0;
      let navbarHeight = 0;
      if (topbar.length > 0) {
        topbarHeight = topbar[0].offsetHeight;
      }
      if (navbar.length > 0) {

        navbarHeight = navbar[0].offsetHeight;
      }
      let navHeight = topbarHeight + navbarHeight + 1; //+1 because of border
      document.documentElement.style.setProperty('--top-menu-height', navHeight + "px");

      document.body.style.maxHeight = 'initial';
      document.body.style.overflow = 'initial';

      addEventListener("resize", () => {
        if (topbar.length > 0) {
          topbarHeight = topbar[0].offsetHeight;
        }
        if (navbar.length > 0) {

          navbarHeight = navbar[0].offsetHeight;
        }
        navHeight = topbarHeight + navbarHeight + 1;
        document.documentElement.style.setProperty('--top-menu-height', navHeight + "px");
      });
    }, 200)

    if (this.isDealerVersion == 'true') {
      this.singleDealer.dealerSlug = this.dealerSlug;
      this.getSingleDealerData();
      this.getSingleDealerCountry();
    }



  },
  computed: {
    computedHiCap() {
      if (this.upperCap == 1) { return 0 }
      if (this.upperCap == 2) { return 50 }
      if (this.upperCap == 3) { return 75 }
      if (this.upperCap == 4) { return 100 }
      if (this.upperCap == 5) { return 125 }
      if (this.upperCap == 6) { return 150 }
      if (this.upperCap == 7) { return 175 }
      if (this.upperCap == 8) { return 200 }
      if (this.upperCap == 9) { return 210 }
      if (this.upperCap == 10) { return 999 }
      //mobile breakpoints
      if (Number(this.upperCap) == 2.5) { return 50 }
      if (Number(this.upperCap) == 4) { return 90 }
      if (Number(this.upperCap) == 5.5) { return 130 }
      if (Number(this.upperCap) == 7) { return 170 }
      if (Number(this.upperCap) == 8.5) { return 210 }
    },
    computedLowCap() {
      if (this.lowerCap == 1) { return 0 }
      if (this.lowerCap == 2) { return 50 }
      if (this.lowerCap == 3) { return 75 }
      if (this.lowerCap == 4) { return 100 }
      if (this.lowerCap == 5) { return 125 }
      if (this.lowerCap == 6) { return 150 }
      if (this.lowerCap == 7) { return 175 }
      if (this.lowerCap == 8) { return 200 }
      if (this.lowerCap == 9) { return 210 }
      if (this.lowerCap == 10) { return 999 }
      //mobile breakpoints
      if (Number(this.lowerCap) == 2.5) { return 50 }
      if (Number(this.lowerCap) == 4) { return 90 }
      if (Number(this.lowerCap) == 5.5) { return 130 }
      if (Number(this.lowerCap) == 7) { return 170 }
      if (Number(this.lowerCap) == 8.5) { return 210 }
    }
  },
  methods: {
    setShowBundles(v) {
      this.showBundles = true;
    },
    switchLocale() {

      var locale = this.$i18n.locale;
      const to = this.$router.resolve({ params: { lang: locale } })
      this.$router.push(to.location).catch(err => {
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          // But print any other errors to the console
          console.log(err);
        }
      })
    },
    resetSelectedProducts() {
      this.selectedProducts = [];
      this.$forceUpdate();
    },
    resetImgOverlays() {
      this.imageOverlays = [];
    },
    resetTank() {
      this.selectedTank = {};
      this.$forceUpdate();
    },
    fetchData() {
      let that = this;
      let country = { 'isocode': this.selectedCountry.isocode2, 'lang': this.siteLanguage };
      this.loadingBundles = true;
      let url = '';
      if (this.showProp == 'true') {
        url = '/public-api/myreefer/list-bundles/private/json'
      } else {
        url = "/public-api/myreefer/list-bundles/json"
      }
      axios
        .post(process.env.VUE_APP_RSF_API + url, country)
        .then(function (response) {

          that.dataNormalBundles = [];
          that.dataDiscountBundles = [];

          if (that.dataNormalBundles.length == 0) {
            for (let i = 0; i < response.data.data_normalBundles.length; i++) {
              that.dataNormalBundles.push(response.data.data_normalBundles[i]);
            }
          }

          if (that.dataDiscountBundles.length == 0) {
            for (let x = 0; x < response.data.data_discountedBundles.length; x++) {
              that.dataDiscountBundles.push(response.data.data_discountedBundles[x]);
            }
          }

          that.dataFetched = true;
          that.loadingBundles = false;

        })
        .catch(function (error) {

        });

    },
    getSingleDealerData() {
      let that = this;
      let url = `/public-api/dealers/whitelabel-myreefer-internal/${this.dealerSlug}/json`
      axios
        .get(process.env.VUE_APP_RSF_API + url)
        .then(function (response) {
          that.singleDealer.dealerLogo = response.data.dealerLogo;
          that.singleDealer.dealerName = response.data.dealerName;
          that.singleDealer.myreeferStoreName = response.data.myreeferStoreName;
          EventBus.$emit('update_single_dealer_data');
        })
        .catch(function (error) {
        });
    },
    getSingleDealerCountry() {
      let that = this
      let url = process.env.VUE_APP_RSF_API + "/public-api/list-countries/json"
      axios
        .post(url, { 'lang': this.siteLanguage })
        .then(function (response) {
          let countries = response.data.data
          for (let i = 0; i < countries.length; i++) {
            if (countries[i].isocode2 == that.dealerIsoCode) {
              that.initDealerCountry(countries[i]);
              return;
            }
          }
        })
        .catch(function (error) {
          // window.alert('Ooops... something wnet wrrong here! We are sorry.');
        });
    },
  updateSelectedProducts(product) {
    let that = this
    //check if product is already in array
    //if product amount is 0 and in aray, remove from array
    for (let i = 0; i < this.selectedProducts.length; i++) {
      if (this.selectedProducts[i].id == product.id) {
        if (product.amount == 0) {
          EventBus.$emit('update_amount', ({
            RNumber: that.selectedProducts[i].RNumber,
            amount: 0
          }))
          this.selectedProducts.splice(i, 1);
          this.computeImageOverlays();
          this.calculateTotal();
          return
        }
        if (product.amount != 0) {
          this.selectedProducts[i].amount = product.amount;
          this.computeImageOverlays();
          this.calculateTotal();
          EventBus.$emit('update_amount', ({
            RNumber: that.selectedProducts[i].RNumber,
            amount: that.selectedProducts[i].amount
          }))
          return
        }
      }
    }
    if (product.amount > 0) {
      this.selectedProducts.push(product)
      this.computeImageOverlays();
      this.calculateTotal();
    }
  },
  updateUpperCap(val) { this.upperCap = val },
  updateLowerCap(val) { this.lowerCap = val },
  updateCountry(v) {
    this.selectedCountry = v;
    if (this.selectedCountry.isocode2 == 'US') {
      this.updateMeasurement('imperial');
    } else {
      this.updateMeasurement('metric');
    }
    this.fetchDiscountEventData();
  },
  initDealerCountry(v) {
    this.selectedCountry = v;
    if (this.selectedCountry.isocode2 == 'US') {
      this.updateMeasurement('imperial');
    } else {
      this.updateMeasurement('metric');
    }
    this.fetchDiscountEventData();
    // this.$router.push({ path: '/' + this.$i18n.locale + '/tank-selection' });

  },
  fetchDiscountEventData() {
    let that = this;
    let country = { 'isocode': this.selectedCountry.isocode2, 'lang': this.siteLanguage };
    let url = '/public-api/myreefer/check-promotion/json'
    axios
      .post(process.env.VUE_APP_RSF_API + url, country)
      .then(function (response) {

        if (response.data.promotionAvailable) {
          let today = new Date();
          let startTime = new Date(response.data.promotionData.startingDatetime)
          let endTime = new Date(response.data.promotionData.endingDatetime)

          //determine if event takes place
          if (today >= startTime && today <= endTime) {
            that.discountEventActive = true;
            that.discountEvent = response.data.promotionData;
          } else {
            that.discountEventActive = false
          }
        }
        else {
          that.discountEventActive = false
        }

      })
      .catch(function (error) {

      });
  },
  updateMeasurement(v) {
    this.measurement = v;
  },
  updateDealer(v) { this.selectedDealer = v; },
  updateFetchedDetailedData(val) { this.fetchedDetailedData = val; },
  findPreselectedProductsCategory() {
    let categoryFound = false
    for (let i = 0; i < this.productCategories.length; i++) {
      if (this.productCategories[i].categoryname == 'Preselected Products') {
        categoryFound = true
        return this.productCategories[i]
      }
    }
    if (!categoryFound) { return false }
  },
  updateCabinetColor(color) {
    //TODO see if prdoucts need to be switched
    this.selectedColor = color;
    let kickCount = 0;
    for (let i = 0; i < this.selectedProducts.length; i++) {

      if (this.selectedProducts[i].isColorSensitive == '1' && this.selectedProducts[i].colorSensitiveLinkedProduct != '') {
        let linkedProduct = {}
        let preselectedProductsCategory = this.findPreselectedProductsCategory()
        if (preselectedProductsCategory == false) {
          continue;
        } else {
          linkedProduct = preselectedProductsCategory.products.find(el => el.RNumber == this.selectedProducts[i].colorSensitiveLinkedProduct)
          linkedProduct.amount = this.selectedProducts[i].amount
          this.selectedProducts.splice(i, 1, linkedProduct);
          this.calculateTotal();
          this.computeImageOverlays();
          continue;
        }

      }

      if (this.selectedProducts[i].onlyVisibleWhenColorsMatched) {
        if (this.selectedProducts[i].Color != this.selectedColor) {
          this.selectedProducts.splice(i, 1);
          kickCount++;
          this.calculateTotal();
          this.computeImageOverlays();
        }
      }
    }
    if (kickCount > 0) {
      this.openModal(
        this.$t('rsf_popup_alert_notice'),
        this.$t('Some of your selected products did not match the updated color, so they were removed from your shopping cart.')
      )
    }
    EventBus.$emit('get_card_index', ({
      'color': this.selectedColor
    }));
  },
  updateProductsInCategories() {
    for (let i = 0; i < this.selectedProducts.length; i++) {
      for (let z = 0; z < this.productCategories.length; z++) {
        for (let x = 0; x < this.productCategories[z].products.length; x++) {
          if (this.selectedProducts[i].id == this.productCategories[z].products[x].id) {
            this.productCategories[z].products[x] = this.selectedProducts[i]
            this.computeImageOverlays()
          }
        }
      }
    }
  },
  updateSelectedTank(tankInfo) {
    this.selectedTank.MainProduct1 = tankInfo.MP1
    this.selectedTank.MainProduct2 = tankInfo.MP2
  },
  fetchProductCategories() {
    let that = this;
    let country = { 'isocode': this.selectedCountry.isocode2, "lang": this.siteLanguage }
    let source = `${process.env.VUE_APP_RSF_API}/public-api/myreefer/get-bundle/${this.selectedTank.id}/json`
    this.loadingProducts = true;
    this.updateFetchedDetailedData(false)
    axios
      .post(source, country)
      .then(function (response) {

        that.bundleRules = response.data.bundleRules;

        that.updateSelectedTank({
          'MP1': response.data.MainProduct1,
          'MP2': response.data.MainProduct2
        });

        // if we already have selected products, we dont need to replace our already fetched products
        if (that.selectedProducts.length == 0) {
          that.productCategories = response.data.bundleCategories
          for (let i = 0; i < that.productCategories.length; i++) {
            for (let x = 0; x < that.productCategories[i].products.length; x++) {
              that.productCategories[i].products[x].amount = 0;
            }
          }
        }

        //cant have both peninsulanomount AND extendedmountsubcategory

        if (response.data.bundleHead.peninsulaNoMount == 1) {
          for (let i = 0; i < response.data.bundleCategories.length; i++) {
            if (response.data.bundleCategories[i].category_id == 4) {
              response.data.bundleCategories[i].dividerElements.splice(0, 1);
              response.data.bundleCategories[i].dividerElements[0].moduloNumber = '2';
            }
          }
        }

        that.bundleHead = response.data.bundleHead;

        if (that.bundleHead.extendedMountSubcategory == '1' && that.productCategories[0].dividerElements.length > 0) {
          that.productCategories[0].dividerElements[1].moduloNumber = '6';
        }

        that.updateFetchedDetailedData(true);
        that.calculateTotal();
        // that.$forceUpdate();
        that.preselectProducts();

        that.loadingProducts = false;

      })
      .catch(function (error) {
        // window.alert('Ooops... something wnet wrrong here! We are sorry.');
        // that.$router.push('/');
      });
  },
  preselectProducts() {
    this.productCategories.forEach(category => {
      for (let i = 0; i < category.products.length; i++) {
        let product = category.products[i];
        if (product.isPreselected == '1' && (product.Color == 'no-color' || product.Color == this.selectedColor)) {
          product.amount = Number(product.preselectAmount);
          this.updateSelectedProducts(product);
        }
        if (product.isPreselected == '1' && product.Color != 'no-color' && product.Color != this.selectedColor) {
          continue;
        }
      }
    })
  },
  changeUnit(useCm) {
    if (useCm) {
      this.unit = 'cm'
    } else {
      this.unit = 'inch'
    }
  },
  selectTank(tank) {
    this.selectedTank = tank
    this.selectedTank.MainProduct1 = { 'Color': '' }
    this.selectedTank.MainProduct2 = { 'Color': '' }
    this.$router.push({ path: '/' + this.$i18n.locale + '/configurator' })
  },
  toggleModal() {
    this.showModal = !this.showModal;
  },
  toggleBundleRulesLightbox() {
    this.showBundlesRulesLightbox = !this.showBundlesRulesLightbox;
  },
  toggleShowAskForMountsLightBox() {
    this.showAskForMountsLightBox = !this.showAskForMountsLightBox;
  },
  openModal(heading, message) {
    this.modalHeading = heading;
    this.modalMessage = message;
    //this.toggleModal();
    this.showModal = true;
  },
  toggleSendCopyForm() {
    if (!this.showSendCopyForm) {
      if (this.checkBundleRules(1)) { return; };
      if (this.checkForMissingMountsAndTrays(1)) { return; };
    }
    this.showSendCopyForm = !this.showSendCopyForm
  },
  toggleSendToDealerForm() {
    if (!this.showSendToDealerForm) {
      if (this.checkBundleRules(2)) { return; };
      if (this.checkForMissingMountsAndTrays(2)) { return; };
    }
    this.showSendToDealerForm = !this.showSendToDealerForm
    this.autoOpened = false;
  },
  checkBundleRules(continueFunction) {
    this.proceedBundleRulesLightboxFunction = continueFunction;
    for (let i = 0; i < this.bundleRules.length; i++) {
      if (this.checkRuleValidity(this.bundleRules[i])) {
        let rule = this.bundleRules[i];
        this.bundleRulesLightboxHeading = rule.messageboxHeadline;
        this.bundleRulesLightboxMessage = rule.messageboxText;
        this.bundleRulesLightboxBtnMsgProceed = rule.messageboxLeftButtonText;
        this.bundleRulesLightboxBtnMsgCancel = rule.messageBoxRightButtonText;
        this.toggleBundleRulesLightbox();
        return true;
      };
    }
    return false;
  },
  checkRuleValidity(rule) {
    let ruleProducts = rule.listOfItems.split(',');
    let requiredProducts = rule.requiredListOfItems.split(',')
    let selectedRuleProductCount = 0;
    let selectedRequiredPrdoucts = 0;
    for (let i = 0; i < this.selectedProducts.length; i++) {
      for (let j = 0; j < ruleProducts.length; j++) {
        if (this.selectedProducts[i].RNumber == ruleProducts[j]) {
          selectedRuleProductCount = selectedRuleProductCount + this.selectedProducts[i].amount;
        }
      }
    }

    if (!(selectedRuleProductCount >= rule.minAmount && selectedRuleProductCount <= rule.maxAmount)) {
      //rule doesnt apply
      return false;
    }

    for (let i = 0; i < this.selectedProducts.length; i++) {
      for (let j = 0; j < requiredProducts.length; j++) {
        if (this.selectedProducts[i].RNumber == requiredProducts[j]) {
          selectedRequiredPrdoucts = selectedRequiredPrdoucts + this.selectedProducts[i].amount;
        }
      }
    }

    if (selectedRequiredPrdoucts < rule.requiredAmount) {
      //rule applies. open lightbox.
      return true;
    }

    return false;
  },
  openSendCopyForm() {
    this.showAskForMountsLightBox = false;
    this.showBundlesRulesLightbox = false;
    this.showSendCopyForm = true;
  },
  checkForMissingMountsAndTrays(continueFunction) {
    if (this.showBundlesRulesLightbox) {
      this.showBundlesRulesLightbox = false;
    }
    if (this.selectedProducts.some(e => e.isLed == true)) {
      this.ledNeedsMounts = false;
      this.ledsNeedTrays = false;
      let mounts = 0;
      let pendant = 0;
      let trays = 0;
      let leds = 0;
      for (let i = 0; i < this.selectedProducts.length; i++) {
        if (this.selectedProducts[i].isMount) {
          mounts = this.selectedProducts[i].amount;
        }
        if (this.selectedProducts[i].isPendant) {
          pendant = this.selectedProducts[i].amount;
        }
        if (this.selectedProducts[i].isTray) {
          trays = this.selectedProducts[i].amount;
        }
        if (this.selectedProducts[i].isLed) {
          leds = this.selectedProducts[i].amount;
        }
      }
      if (mounts < leds && pendant == 0) {
        this.ledNeedsMounts = true;
        this.useFunction = continueFunction;
        this.toggleShowAskForMountsLightBox();
        return true;
      }
      if (pendant == 1 && trays < leds) {
        this.ledsNeedTrays = true;
        this.useFunction = continueFunction;
        this.toggleShowAskForMountsLightBox();
        return true;
      }
    }
    return false;
  },
  openSendToDealerForm() {
    this.showAskForMountsLightBox = false;
    this.showBundlesRulesLightbox = false;
    this.showSendToDealerForm = true;
  },
  interMediateCheckForMountsAndTrays(continueFunction) {
    this.showBundlesRulesLightbox = false;
    if (this.checkForMissingMountsAndTrays(continueFunction)) {
      return;
    }
    if (continueFunction == 1) {
      this.showSendCopyForm = !this.showSendCopyForm
    }
    if (continueFunction == 2) {
      this.showSendToDealerForm = !this.showSendToDealerForm
    }
  },
  formatCurrency(currency, price) {

    let hasDecimal = false;
    let splitPrice = price.toString().split('.');
    if (parseFloat(splitPrice[1]) != 0 && splitPrice.length > 1) {
      hasDecimal = true;
    }

    if (currency == 'EUR') {
      if (!hasDecimal) {
        return `€${parseFloat(price)}`
      } else {
        let roundedPrice = parseFloat(price).toFixed(2).toString();
        let euroPrice = roundedPrice.split('.');
        let commaPrice = euroPrice[0] + ',' + euroPrice[1];
        return `€${commaPrice}`
      }
    }
    if (currency == 'JPY') {
      if (!hasDecimal) {
        return `¥${parseFloat(price)}`
      } else {
        return `¥${parseFloat(price)}`
      }
    }
    if (currency == 'AUD') {
      if (!hasDecimal) {
        return `$${parseFloat(price)}`
      } else {
        return `$${parseFloat(price).toFixed(2)}`
      }
    }

    if (currency == 'USD') {
      if (!hasDecimal) {
        return `$${parseFloat(price)}`
      } else {
        return `$${parseFloat(price).toFixed(2)}`
      }
    }

    if (currency == 'GBP') {
      if (!hasDecimal) {
        return `£${parseFloat(price)}`
      } else {
        return `£${parseFloat(price).toFixed(2)}`
      }
    }
  },
  computeImageOverlays() {
    this.loadingImages = true;
    this.imageOverlays = [];

    for (let i = 0; i < this.selectedProducts.length; i++) {
      for (let x = 0; x < this.selectedProducts[i].specialOverlayImages.length; x++) {
        if (this.selectedProducts[i].amount == Number(this.selectedProducts[i].specialOverlayImages[x].amountOfItemsWhereNeedsToBeOverlayed)) {
          this.imageOverlays.push(this.selectedProducts[i].specialOverlayImages[x]);
        }
      }
    }
    setTimeout(() => { this.loadingImages = false; }, 300);
    this.computeWavePumpEdgeCase();
  },
  computeWavePumpEdgeCase() {

    const rw25 = this.selectedProducts.find(product => product.RNumber == "R35210");
    const rw45 = this.selectedProducts.find(product => product.RNumber == "R35220");

    if (!(rw25 && rw45)) { return; }

    if (rw25.amount + rw45.amount != 3) { return; }

    if (this.imageOverlays.find(img => img.specialImagePath == rw45.specialOverlayImages[2].specialImagePath)) { return; }

    this.imageOverlays.push(rw45.specialOverlayImages[2]);

  },

  calculateTotal() {

    let cumulatedProductsPrice = 0;
    let cumulatedPreselectedProductsPrice = 0;
    let tankPrice = 0;
    let eventDiscount = 0;
    let total = 0;
    let cumulatedPricesWithDiscount = 0;

    // cumulate prices for products price for products
    for (let i = 0; i < this.selectedProducts.length; i++) {
      // differentiate between discounted and non-discounted product
      if (this.selectedProducts[i].prices.isDiscounted) {
        cumulatedProductsPrice = cumulatedProductsPrice + parseFloat(this.selectedProducts[i].prices.discountprice) * this.selectedProducts[i].amount;
      } else {
        cumulatedProductsPrice = cumulatedProductsPrice + parseFloat(this.selectedProducts[i].prices.retailprice) * this.selectedProducts[i].amount;
      }
    }

    // cumulate prices for preselected products
    for (let i = 0; i < this.selectedProducts.length; i++) {
      if (this.selectedProducts[i].Category != 'Preselected Products') { continue }
      // differentiate between discounted and non-discounted product
      if (this.selectedProducts[i].prices.isDiscounted) {
        cumulatedPreselectedProductsPrice = cumulatedPreselectedProductsPrice + parseFloat(this.selectedProducts[i].prices.discountprice) * this.selectedProducts[i].amount;
      } else {
        cumulatedPreselectedProductsPrice = cumulatedPreselectedProductsPrice + parseFloat(this.selectedProducts[i].prices.retailprice) * this.selectedProducts[i].amount;
      }
    }

    // get aquarium price
    let aquariumPrice = this.selectedTank.mainProductPrices // aquarium product prices
    if (aquariumPrice.isDiscounted) {
      tankPrice = parseFloat(aquariumPrice.discountprice);
    } else {
      tankPrice = parseFloat(aquariumPrice.retailprice);
    }

    total = cumulatedProductsPrice + tankPrice;
    cumulatedPricesWithDiscount = cumulatedProductsPrice + tankPrice;

    //check for discountEvent
    if (this.discountEventActive) {
      eventDiscount = Number(this.discountEvent.discountinpercent) / 100;
      total = total - (total * eventDiscount);
    }

    // with MyReefer-discount & event-discount
    this.totalUnformatted = total;
    this.total = this.formatCurrency(aquariumPrice.currency, total);

    // cumulate product prices without discount
    let subtotal = 0;
    for (let i = 0; i < this.selectedProducts.length; i++) {
      subtotal = subtotal + parseFloat(this.selectedProducts[i].prices.retailprice) * this.selectedProducts[i].amount
    }

    // cumulate preselected product prices without discount
    let cumulatedPreselectedProductsPriceWithoutDiscount = 0;
    for (let i = 0; i < this.selectedProducts.length; i++) {
      if (this.selectedProducts[i].Category != 'Preselected Products') { continue }
      cumulatedPreselectedProductsPriceWithoutDiscount = cumulatedPreselectedProductsPriceWithoutDiscount + parseFloat(this.selectedProducts[i].prices.retailprice) * this.selectedProducts[i].amount
    }


    // add aquarium price without discount
    subtotal = subtotal + parseFloat(this.selectedTank.mainProductPrices.retailprice);

    // Subtotal (without any discount)
    this.subTotalUnformatted = subtotal;
    this.subTotal = this.formatCurrency(this.selectedTank.mainProductPrices.currency, subtotal);

    // SubTotal of Preselected products (without any discount)
    this.subTotalPreselectedUnformatted = cumulatedPreselectedProductsPriceWithoutDiscount + parseFloat(this.selectedTank.mainProductPrices.retailprice);
    this.subTotalPreselected = this.formatCurrency(this.selectedTank.mainProductPrices.currency, this.subTotalPreselectedUnformatted);

    // Total of preselected prdoucts
    this.totalPreselectedUnformatted = cumulatedPreselectedProductsPrice + tankPrice;
    this.totalPreselected = this.formatCurrency(this.selectedTank.mainProductPrices.currency, this.totalPreselectedUnformatted);


    // MyReefer-Discount amount
    this.myReeferDiscountAmountUnformatted = this.subTotalUnformatted - cumulatedPricesWithDiscount;
    this.myReeferDiscountAmount = this.formatCurrency(this.selectedTank.mainProductPrices.currency, this.myReeferDiscountAmountUnformatted);

    // Event-Discount amount
    this.eventDiscountAmountUnformatted = cumulatedPricesWithDiscount * eventDiscount;
    this.eventDiscountAmount = this.formatCurrency(this.selectedTank.mainProductPrices.currency, this.eventDiscountAmountUnformatted);

    // sum of the 2 discount amounts
    this.totalSavingsUnformatted = this.myReeferDiscountAmountUnformatted + this.eventDiscountAmountUnformatted;
    if (this.totalSavingsUnformatted > 0) {
      this.totalSavings = this.formatCurrency(this.selectedTank.mainProductPrices.currency, this.totalSavingsUnformatted);
    } else {
      this.totalSavings = '';
    }
  },
  autoOpenDealerForm() {
    this.openSendToDealerForm();
    this.autoOpened = true;
  }
}
}
</script>

<style>
@import './css/myreefer-styles.css';

#app {
  font-family: 'Open Sans', sans-serif;
  min-height: 100vh;
}
</style>
